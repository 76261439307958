<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物分笼</h3>
    <div class="detail">
      <el-form ref="addForm" size="small" :model="cage" class="addForm" label-suffix=":"
               label-width="80px">
        <h3 class="tit">动物分笼(申请笼舍数量：{{ cage.totalCageNum }})</h3>
        <el-tabs v-model="cage.nowNumber" class="tabsCage" type="card" @tab-click="clickTab">
          <el-tab-pane
              :key="item.id"
              v-for="item in cage.cageList"
              :label="item.number"
              :name="String(item.id)"
          >
            <div class="desc">
              <span>笼架编号：{{ item.frameNo }}</span>
            </div>
            <el-table
                :row-class-name="state==0?tableRowClassName:''"
                :data="item.partAniList" border
                class="tableMember" max-height="400"
                :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                style="width: calc(100% - 12px);font-size:14px;color:#333">
              <el-table-column label="耳号" width="150">
                <template slot-scope="scope">
                  <template v-if="scope.$index==0&&state==0">
                    <el-select v-model="cageForm.overbit" filterable size="small" placeholder="请选择耳号"
                               @change="changeOverbit($event,cageForm)">
                      <el-option
                          v-for="(item,i) in overbitList"
                          :key="i"
                          :label="item.overbit"
                          :value="String(item.id)"
                          :disabled="item.disabled">
                      </el-option>
                    </el-select>
                  </template>
                  <template v-else>
                    <template v-if="scope.row.show">
                      <el-select v-model="scope.row.overbit" filterable size="small" placeholder="请选择耳号"
                                 @change="changeOverbit($event,scope.row,scope.$index)">
                        <el-option
                            v-for="(item,i) in overbitList"
                            :key="i"
                            :label="item.overbit"
                            :value="String(item.id)"
                            :disabled="item.disabled">
                        </el-option>
                      </el-select>
                    </template>
                    <span v-else>{{ scope.row.overbit }}</span>
                  </template>

                  <!--                  <el-input v-model="cageForm.overbit" size="small"
                                              placeholder="请输入耳号"></el-input>
                                    <template v-else>
                                      <template v-if="scope.row.show ">
                                        <el-input
                                            size="small"
                                            placeholder="请输入耳号"
                                            v-model="scope.row.overbit"></el-input>
                                      </template>
                                      <span v-else>{{ scope.row.overbit ? scope.row.overbit : scope.row.id }}</span>
                                    </template>-->
                </template>
              </el-table-column>
              <el-table-column
                  label="性别" width="150">
                <template slot-scope="scope">
                   <span v-if="scope.$index==0&&state==0">{{
                       cageForm.gender == '0' ? '♀' : cageForm.gender == '1' ? '♂' : ' '
                     }}</span>
                  <template v-else>
                    {{ scope.row.gender == '0' ? '♀' : scope.row.gender == '1' ? '♂' : ' ' }}
                  </template>
                </template>
                <!--                <template slot-scope="scope">
                                  <el-select v-model="cageForm.gender" v-if="scope.$index==0&&state==0" size="small" placeholder="请选择性别">
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                  </el-select>
                                  <template v-else>
                                    <template v-if="scope.row.show">
                                      <el-select v-model="scope.row.gender" size="small" placeholder="请选择性别">
                                        <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                      </el-select>
                                    </template>
                                    <div v-else>{{ scope.row.gender == '0' ? '♀' : scope.row.gender == '1' ? '♂' : ' ' }}</div>
                                  </template>
                                </template>-->
              </el-table-column>
              <el-table-column
                  label="出生日期" width="160"
                  show-overflow-tooltip>
                <template slot-scope="scope">
                  <template v-if="scope.$index==0&&state==0&&cageForm.birthDate">
                    {{ cageForm.birthDate  |  formatDay }}
                  </template>
                  <template v-else>
                    <template v-if="scope.row.birthDate"> {{ scope.row.birthDate |  formatDay }}</template>
                    <template v-else></template>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                  label="周龄" prop="weekDays"
                  show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.$index==0&&state==0">{{ cageForm.weekDays ? cageForm.weekDays : 0 }}</span>
                  <div v-else>{{ scope.row.weekDays ? scope.row.weekDays : 0 }}</div>
                </template>
              </el-table-column>
              <el-table-column label="体重" min-width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input v-if="scope.$index==0&&state==0" size="small" placeholder="体重"
                            v-model="cageForm.weight"></el-input>
                  <template v-else>
                    <el-input v-if="scope.row.show" size="small" placeholder="体重" v-model="scope.row.weight"></el-input>
                    <span v-else>{{ scope.row.weight }}</span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="备注" min-width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input v-if="scope.$index==0&&state==0" size="small" placeholder="备注"
                            v-model="cageForm.remark"></el-input>
                  <template v-else>
                    <el-input v-if="scope.row.show" size="small" placeholder="备注" v-model="scope.row.remark"></el-input>
                    <span v-else>{{ scope.row.remark }}</span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150" v-if="state==0">
                <template slot-scope="scope">
                  <div class="editColor">
                    <div v-if="scope.$index==0" class="addColor">
                      <el-button type="text" @click="addPart(scope.row)">添加</el-button>
                      <el-button type="text" @click="reset">重置</el-button>
                    </div>
                    <div v-if="scope.$index!=0" class="editColor">
                      <el-button type="text" @click="edit(scope.row,scope.$index)" v-if="!scope.row.show">修改
                      </el-button>
                      <el-button type="text" @click="save(scope.row,scope.$index)" v-else>保存
                      </el-button>
                      <!--                      <el-button type="text" v-if="hidden" @click="copy(scope.row,scope.$index)">复制</el-button>-->
                      <el-button type="text" @click="deleteA(scope.row.id)">删除</el-button>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <div class="fromSave" v-if="state==0">
          <el-button type="primary" size="small" @click="cageSubmit(1)">合笼完成</el-button>
          <el-button plain type="primary" size="small" @click="cageSubmit(0)">保存</el-button>
        </div>
      </el-form>
    </div>
  </div>

</template>

<script>
import moment from "moment";

moment.locale("zh-cn")
const tableBegin = {
  cageId: null,
  gender: 0,
  overbit: "",
  remark: "",
  subId: null,
  weight: "",
  weekDays: "",
  show: false
}

export default {
  name: "pointFinish",
  props: ['id'],
  data() {
    return {
      cage: {
        cageList: [],
        nowNumber: '',
        totalCageNum: null,
        tabIndex: 0
      },
      cageForm: {
        gender: 0,
        overbit: "",
        remark: "",
        weight: "",
        weekDays: "",
        birthDate: ""
      },
      state: null,
      options: [
        {
          value: 0,
          label: '雌性'
        }, {
          label: '雄性',
          value: 1
        }
      ],
      overbitList: [],//耳号列表
      resultList: [],//以保存的数据
      isLabAni: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 获取检疫合格的老鼠
    getQuarantine() {
      this.$get("/ani/quarResult/" + this.id).then(res => {
        if (res.status == 200) {
          let Ids = this.resultList.map(item => item.quarResultId);
          let arr = [];
          res.data.forEach((item, index) => {
            if (Ids.includes(item.id)) {
              item.disabled = true
            } else {
              item.disabled = false
            }
            arr.push(item);
          });
          this.overbitList = arr
        }
      })
    },
    //选择耳号
    changeOverbit(value, row) {
      this.overbitList.forEach((item, index) => {
        if (item.id == value) {
          //回填对应小鼠数据
          row.gender = item.gender
          row.birthDate = item.birthDate
          row.subId = item.subId
          row.overbit = item.overbit
          row.quarResultId = item.id
          this.selectBir(row.birthDate, row)
        }
      })
    },
    // 设置第一行样式
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 0) {
        return 'formAdd';
      }
      return '';
    },
    getData() {
      this.$get('/subject/part/' + this.id).then(res => {
        if (res.status == 200) {
          this.state = res.data.part.state
          // 保存状态查看分笼
          if (this.state == 0) {
            let arr = []
            //表格初始
            res.data.cageList.forEach((item, index) => {
              item.partAniList.unshift(tableBegin)
              item.partAniList.forEach(obj => {
                obj.show = false
                this.selectBir(obj.birthDate, obj)
                arr.push(obj)
              })
            })
            this.resultList = arr
          }
          this.cage.cageList = res.data.cageList

          this.cage.nowNumber = String(res.data.cageList[this.cage.tabIndex].id)
          this.cage.totalCageNum = this.cage.cageList.length

          // 获取耳号列表
          this.getQuarantine()
        }
      })
    },

    // 添加项目成员
    addPart(row) {
      if ($.trim(this.cageForm.overbit).length === 0) {
        this.$message.warning("耳号不能为空");
        return;
      }
      if ($.trim(this.cageForm.gender).length === 0) {
        this.$message.warning("性别不能为空");
        return;
      }
      if ($.trim(this.cageForm.birthDate).length === 0) {
        this.$message.warning("出生日期不能为空");
        return;
      }
      if ($.trim(this.cageForm.weight).length === 0) {
        this.$message.warning("体重不能为空");
        return;
      }
      this.addTable(this.cageForm)
    },
    // 重置
    reset() {
      this.cageForm = {
        cageId: this.cage.cageList[this.cage.tabIndex].id,
        subId: this.cage.cageList[this.cage.tabIndex].subId,
        gender: 0,
        overbit: "",
        remark: "",
        weight: "",
        weekDays: ""
      }
    },
    //tab切换
    clickTab(ev) {
      this.cageForm = {
        gender: 0,
        overbit: "",
        remark: "",
        weight: "",
        weekDays: ""
      }
      this.cageForm.cageId = this.cage.cageList[ev.index].id
      this.cageForm.subId = this.cage.cageList[ev.index].subId
      this.cage.tabIndex = ev.index;//笼舍列表下标
    },
    // 选择出生日期 改变周龄
    selectBir(value, row, index) {
      if (value) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        let birDay = moment(value).format("YYYY-MM-DD");
        let diffTime = moment(today).diff(moment(birDay), 'days');
        let weeksAge = diffTime / 7
        row.weekDays = Math.round(weeksAge)
      }
    },
    // 编辑
    edit(row, index) {
      row.show = true
    },
    // 保存
    save(row, index) {
      let params = {
        id: row.id,
        cageId: row.cageId,
        subId: row.subId,
        overbit: row.overbit,
        remark: row.remark,
        weight: row.weight,
        quarResultId: row.quarResultId
      }
      this.$put('/part/detail', params).then(res => {
        this.$message.success("修改成功")
        row.show = false
      })
    },
    // 复制
    copy(val, index) {
      if (this.state == 0) {
        this.$delete(val, 'id')
        this.addTable(val)
      } else {
        this.preMedi.preMediDetailList.splice(index, 0, JSON.parse(JSON.stringify(val)))
      }
    },
    addTable(animal) {
      let params = {
        cageId: this.cage.cageList[this.cage.tabIndex].id,
        subId: this.cage.cageList[this.cage.tabIndex].subId,
        overbit: animal.overbit,
        remark: animal.remark,
        weight: animal.weight,
        quarResultId: animal.quarResultId
      }
      // 新增小鼠信息
      this.$post('/part/detail', params).then(res => {
        this.$message.success("新增成功")
        this.cageForm = {
          cageId: this.cage.cageList[this.cage.tabIndex].id,
          subId: this.cage.cageList[this.cage.tabIndex].subId,
          gender: 0,
          overbit: "",
          remark: "",
          weight: "",
          weekDays: ""
        }
        // 重新获取当前笼舍小鼠
        this.getData()
        this.cage.nowNumber = String(res.data.cageList[this.cage.tabIndex].id)
      })
    },
    // 删除
    deleteA(id) {
      this.$del('/part/detail/' + id).then(res => {
        this.$message.success("删除成功")
        // 重新获取当前笼舍小鼠
        this.getData()
        this.cage.nowNumber = String(res.data.cageList[this.cage.tabIndex].id)
      })
    },
    // 提交保存
    cageSubmit(state) {
      let params = {
        subId: this.id,
        state: state
      }
      this.$put("/subject/part", params).then(res => {
        this.$message.success("提交成功！")
        this.$router.go(-1)
      }).catch(() => {
        this.$message.error("操作失败")
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
